import { RiCloseLargeLine } from "react-icons/ri";
import Modal from "react-modal";

const ModalDialog = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  children: any;
  fitContent?: boolean;
}) => {
  const { isModalOpen, setIsModalOpen, children, fitContent } = props;
  const customStyles = {
    content: {
      maxHeight: fitContent ? "fit-content" : "none",
      maxWidth: "640px",
      margin: "auto",
    },
    overlay: {
      zIndex: 10,
    },
  };
  return (
    <Modal
      isOpen={isModalOpen}
      style={customStyles}
      onRequestClose={() => setIsModalOpen(false)}
      shouldCloseOnOverlayClick={true}
    >
      <div className="flex flex-row-reverse">
        <button onClick={() => setIsModalOpen(false)}>
          <RiCloseLargeLine title="Close" />
        </button>
      </div>
      {children}
    </Modal>
  );
};

export default ModalDialog;
