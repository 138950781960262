import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/Select";
import Label from "./Label";

const TimeSelect = (props: {
  time: string;
  times: string[];
  required?: boolean;
  setTime: (time: string) => void;
  onOpenChange?: (open: boolean) => void;
}) => {
  const { time, times, setTime, onOpenChange } = props;
  const required = props.required === undefined ? false : props.required;

  return (
    <div className="w-full mb-5">
      <Label htmlFor="select-time">Time</Label>
      <Select
        name="select-time"
        value={time || ""}
        defaultValue={time || ""}
        onValueChange={(value) => setTime(value)}
        required={required}
        onOpenChange={onOpenChange}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select time" />
        </SelectTrigger>
        <SelectContent>
          {times.map((time, i) => (
            <SelectItem key={i} value={time}>
              {time}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default TimeSelect;
