import NavigationContainer from "@/components/layout/NavigationContainer";
import LocationSidebar from "@/components/locations/LocationSidebar";
import CreatePaymentAccount from "@/components/payments/CreatePaymentAccount";
import DisplayPaymentAccount from "@/components/payments/DisplayPaymentAccount";
import LinkPaymentAccount from "@/components/payments/LinkPaymentAccount";
import { auth } from "@/firebase";
import { getLocationById, updateLocation } from "@/services/LocationService";
import { logError } from "@/services/LoggingService";
import { getPaymentAccount } from "@/services/PaymentService";
import { getUserById } from "@/services/UserService";
import Store from "@/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Stripe from "stripe";

const Payments = () => {
  const { locationId } = useParams();
  const { isLoading, setIsLoading } = Store();
  const [refresh, setRefresh] = useState(false);
  const [paymentAccount, setPaymentAccount] = useState<Stripe.Account | null>(
    null,
  );

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId!);
      if (!location.stripeAccountId) {
        const user = await getUserById(auth?.currentUser?.uid);
        if (user?.stripeAccountId) {
          location.stripeAccountId = user.stripeAccountId;
          await updateLocation(location);
        }
      }
      if (location.stripeAccountId) {
        const paymentAccount = await getPaymentAccount(
          location.stripeAccountId,
        );
        if (paymentAccount) {
          setPaymentAccount(paymentAccount);
        }
      }
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, [refresh]);

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId!} />}>
      <h1>Payments</h1>
      {isLoading && <div>Loading...</div>}
      {!isLoading && !paymentAccount && (
        <CreatePaymentAccount setRefresh={setRefresh} />
      )}
      {!isLoading && paymentAccount && !paymentAccount.details_submitted && (
        <LinkPaymentAccount
          stripeAccountId={paymentAccount.id}
          locationId={locationId!}
        />
      )}
      {!isLoading && paymentAccount && paymentAccount.details_submitted && (
        <DisplayPaymentAccount />
      )}
    </NavigationContainer>
  );
};

export default Payments;
