import { clsx, type ClassValue } from "clsx";
import { DayOfWeek } from "shared/utils/Enums";
import { twMerge } from "tailwind-merge";

/**
 * Merges class names using clsx and tailwind-merge.
 * @param {...ClassValue[]} inputs - The class values to merge.
 * @return {string} - The merged class names.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Converts a string to title case.
 * @param {string} s - The string to convert.
 * @return {string} - The converted string in title case.
 */
export function toTitleCase(s: string): string {
  return s
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

/**
 * Retrieves a URL parameter by name.
 * @param {string} name - The name of the parameter.
 * @return {string} - The parameter value.
 */
export function getParameterByName(name: string) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regexS = "[\\?&]" + name + "=([^&#]*)";
  const regex = new RegExp(regexS);
  const results = regex.exec(window.location.href);
  if (results == null) return "";
  else return decodeURIComponent(results[1].replace(/\+/g, " "));
}

/**
 * Formats a price in the specified currency.
 * @param {number} price - The price in cents.
 * @param {string} currency - The currency code.
 * @return {string} - The formatted price.
 */
export function formatPrice(price: number, currency: string) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(price / 100);
}

/**
 * Gets the day of the week for a given date.
 * @param {Date} date - The date object.
 * @return {DayOfWeek} - The day of the week.
 */
export function getDayOfWeek(date: Date) {
  switch (date.getDay()) {
    case 0:
      return DayOfWeek.Sunday;
    case 1:
      return DayOfWeek.Monday;
    case 2:
      return DayOfWeek.Tuesday;
    case 3:
      return DayOfWeek.Wednesday;
    case 4:
      return DayOfWeek.Thursday;
    case 5:
      return DayOfWeek.Friday;
    case 6:
      return DayOfWeek.Saturday;
    default:
      throw new Error("Invalid day of week");
  }
}

/**
 * Formats an array of days of the week into a readable string.
 * @param {DayOfWeek[]} days - The array of days.
 * @return {string} - The formatted string.
 */
export function formatDaysOfWeek(days: DayOfWeek[]) {
  if (days.length === 0) return "";

  const dayMap: Record<DayOfWeek, number> = {
    [DayOfWeek.Monday]: 1,
    [DayOfWeek.Tuesday]: 2,
    [DayOfWeek.Wednesday]: 3,
    [DayOfWeek.Thursday]: 4,
    [DayOfWeek.Friday]: 5,
    [DayOfWeek.Saturday]: 6,
    [DayOfWeek.Sunday]: 7,
  };

  const sortedDays = [...days].sort((a, b) => dayMap[a] - dayMap[b]);
  const ranges: string[] = [];
  let currentSequence: DayOfWeek[] = [sortedDays[0]];

  for (let i = 1; i < sortedDays.length; i++) {
    if (dayMap[sortedDays[i]] === dayMap[sortedDays[i - 1]] + 1) {
      currentSequence.push(sortedDays[i]);
    } else {
      if (currentSequence.length >= 3) {
        ranges.push(
          `${toTitleCase(currentSequence[0])} - ${toTitleCase(currentSequence[currentSequence.length - 1])}`,
        );
      } else {
        ranges.push(...currentSequence.map(toTitleCase));
      }
      currentSequence = [sortedDays[i]];
    }
  }

  if (currentSequence.length >= 3) {
    ranges.push(
      `${toTitleCase(currentSequence[0])} - ${toTitleCase(currentSequence[currentSequence.length - 1])}`,
    );
  } else {
    ranges.push(...currentSequence.map(toTitleCase));
  }

  return ranges.join(", ");
}

/**
 * Extracts the hour from a time string.
 * @param {string} time - The time string in "h:mm A" format.
 * @return {number} - The hour.
 */
export function getHourFromTimeString(time: string) {
  let hour = parseInt(time.split(":")[0]);
  if (hour === 12 && time.includes("AM")) {
    hour = 0;
  } else if (hour !== 12 && time.includes("PM")) {
    hour += 12;
  }
  return hour;
}

/**
 * Extracts the minute from a time string.
 * @param {string} time - The time string in "h:mm A" format.
 * @return {number} - The minute.
 */
export function getMinuteFromTimeString(time: string) {
  return parseInt(time.split(":")[1].split(" ")[0]);
}

/**
 * Gets the time from a date object.
 * @param {Date} date - The date object.
 * @return {string} - The time string in "h:mm A" format.
 */
export function getTimeFromDate(date: Date) {
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
}

/**
 * Sets the time on a date object.
 * @param {Date} date - The date object.
 * @param {string} time - The time string in "h:mm A" format.
 * @return {Date} - The updated date object.
 */
export function setDateTime(date: Date, time: string) {
  const hour = getHourFromTimeString(time);
  const minute = getMinuteFromTimeString(time);
  date.setHours(hour, minute);
  return date;
}

/**
 * Validates a date string.
 * @param {string} date - The date string in MM/DD/YYYY format.
 * @return {boolean} - True if the date is valid, false otherwise.
 */
export function isValidDate(date: string) {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  return regex.test(date);
}

/**
 * Validates a time string.
 * @param {string} time - The time string in "h:mm A" format.
 * @return {boolean} - True if the time is valid, false otherwise.
 */
export function isValidTime(time: string) {
  const regex = /^(1[0-2]|0?[1-9]):[0-5][0-9] [AP]M$/i;
  return regex.test(time);
}
