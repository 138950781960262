import { auth } from "@/firebase";
import { Product } from "shared/types/Product";
import { getLocationById, updateLocation } from "./LocationService";

const baseUrl = `https://${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`;

/**
 * Fetches products for a given location.
 * @param {string} locationId - The ID of the location.
 * @return {Promise<Product[]>} - A promise that resolves to an array of products.
 * @throws {Error} - If the fetch operation fails.
 */
export const getProducts = async (locationId: string) => {
  const url = new URL(`${baseUrl}/getProducts`);
  url.searchParams.append("locationId", locationId);
  const response = await fetch(url, {
    method: "GET",
  });
  if (!response.ok) {
    throw new Error(`Failed to fetch products: ${response.statusText}`);
  }
  const data = await response.json();
  return data as Product[];
};

/**
 * Creates a new product for a given location.
 * @param {string} locationId - The ID of the location.
 * @param {Product} product - The product to create.
 * @return {Promise<void>} - A promise that resolves when the product is created.
 * @throws {Error} - If the creation operation fails.
 */
export const createProduct = async (locationId: string, product: Product) => {
  const url = new URL(`${baseUrl}/createProduct`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      locationId: locationId,
      product: product,
    }),
  });
  if (!response.ok) {
    throw new Error(`Failed to create product: ${response.statusText}`);
  }
  const location = await getLocationById(locationId);
  location.products.push(product);
  await updateLocation(location);
};

/**
 * Updates an existing product.
 * @param {Product} product - The product to update.
 * @return {Promise<void>} - A promise that resolves when the product is updated.
 * @throws {Error} - If the update operation fails.
 */
export const updateProduct = async (product: Product) => {
  const url = new URL(`${baseUrl}/updateProduct`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      product: product,
    }),
  });
  if (!response.ok) {
    throw new Error(`Failed to update product: ${response.statusText}`);
  }
};

/**
 * Deletes a product by its ID.
 * @param {string} productId - The ID of the product to delete.
 * @return {Promise<void>} - A promise that resolves when the product is deleted.
 * @throws {Error} - If the deletion operation fails.
 */
export const deleteProductById = async (productId: string) => {
  const url = new URL(`${baseUrl}/deleteProduct`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      productId: productId,
    }),
  });
  if (!response.ok) {
    throw new Error(`Failed to delete product: ${response.statusText}`);
  }
};
