import Store from "@/store";
import * as Sentry from "@sentry/react";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { Outlet } from "react-router-dom";
import { auth } from "../../firebase";
import SentryError from "../authentication/SentryError";

function LayoutContainer() {
  const { isLoading, setUser } = Store();

  useEffect(() => {
    if (auth) {
      onAuthStateChanged(auth, (user) => {
        setUser(user);
        if (user) {
          Sentry.setUser({ email: user.email ?? "" });
        } else {
          Sentry.setUser(null);
        }
      });
    }
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <SentryError
          error={error}
          componentStack={componentStack}
          resetError={resetError}
        />
      )}
      showDialog={true}
    >
      <div className="relative">
        <LoadingOverlay
          active={isLoading}
          spinner
          text="Loading..."
          styles={{
            wrapper: { height: "100svh", display: "flex" },
          }}
        >
          <div className={`${isLoading ? "hidden" : "block"} w-full`}>
            <Outlet />
          </div>
        </LoadingOverlay>
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default LayoutContainer;
