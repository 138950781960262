import { Employee } from "shared/types/Employee";
import PlaceholderImage from "/images/avatar-placeholder.png";

const AppointmentEmployeeFilter = (props: {
  employees: Employee[];
  selectedEmployees: Employee[];
  setSelectedEmployees: (selectedEmployees: Employee[]) => void;
}) => {
  const { employees, selectedEmployees, setSelectedEmployees } = props;
  const toggleEmployeeSelection = (employee: Employee) => {
    const isSelected = selectedEmployees.some(
      (selectedEmployee) => selectedEmployee.id === employee.id,
    );
    const updatedSelectedEmployees = isSelected
      ? selectedEmployees.filter(
          (selectedEmployee) => selectedEmployee.id !== employee.id,
        )
      : [...selectedEmployees, employee];
    setSelectedEmployees(updatedSelectedEmployees);
  };
  return (
    <div>
      {employees.map((employee, index) => {
        return (
          <div key={index} className={`my-4 text-xl rounded-full`}>
            <button
              className={`rounded-full w-[75px] h-[75px] ${selectedEmployees.some((selectedEmployee) => selectedEmployee.id === employee.id) ? "border-monkey-purple border-4 " : "border-none"}`}
              onClick={() => toggleEmployeeSelection(employee)}
            >
              <div>
                <img
                  src={employee.image || PlaceholderImage}
                  className="rounded-full"
                ></img>
                <div className="mt-1 text-sm">{employee.firstName}</div>
              </div>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default AppointmentEmployeeFilter;
