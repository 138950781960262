import { User } from "firebase/auth";
import { create } from "zustand";

type Store = {
  // layout
  isLoading: boolean;
  loadingCount: number;
  sidebarVisible: boolean;
  sidebarCollapsed: boolean;
  setIsLoading: (isLoading: boolean) => void;
  toggleSidebar: () => void;
  collapseSidebar: () => void;
  uncollapseSidebar: () => void;
  setSidebarVisible: () => void;

  // auth
  user: User | null;
  setUser: (user: User | null) => void;
};

const Store = create<Store>((set) => ({
  // layout
  isLoading: false,
  loadingCount: 0,
  sidebarVisible: false,
  sidebarCollapsed: false,
  setIsLoading: (isLoading) => {
    set((state) => {
      if (state.loadingCount === 0 && isLoading) {
        return { isLoading, loadingCount: 1 };
      } else if (isLoading) {
        return { loadingCount: state.loadingCount + 1 };
      } else if (state.loadingCount === 1 && !isLoading) {
        return { isLoading, loadingCount: 0 };
      } else if (!isLoading) {
        return { loadingCount: state.loadingCount - 1 };
      }
      return {};
    });
  },
  toggleSidebar: () =>
    set((state) => ({ sidebarCollapsed: !state.sidebarCollapsed })),
  collapseSidebar: () => set({ sidebarCollapsed: true }),
  uncollapseSidebar: () => set({ sidebarCollapsed: false }),
  setSidebarVisible: () =>
    set(() => ({
      sidebarVisible:
        document.getElementsByClassName("ps-sidebar-root").length > 0,
    })),

  // auth
  user: null,
  setUser: (user) => set({ user }),
}));

export default Store;
