import { doc, getDoc, getFirestore } from "firebase/firestore/lite";
import { Employee } from "shared/types/Employee";
import { app } from "../firebase";

const db = getFirestore(app);

/**
 * Function to get an employee by their ID
 * @param {string} employeeId - The ID of the employee to fetch
 * @return {Promise<Employee>} - A promise that resolves to the employee object
 * @throws {Error} - Throws an error if no employee is found or if there is an issue fetching the employee
 */
export const getEmployeeById = async (
  employeeId: string,
): Promise<Employee> => {
  try {
    const employeeRef = doc(db, "employees", employeeId);
    const employeeDoc = await getDoc(employeeRef);

    if (!employeeDoc.exists() || employeeDoc.data().deleted) {
      throw new Error(`No employee found with ID: ${employeeId}`);
    }

    return employeeDoc.data() as Employee;
  } catch (error) {
    console.error(`Error fetching employee: ${error}`);
    throw error;
  }
};
