import { EmployeeAvailability } from "shared/types/EmployeeAvailability";
import EmployeeAvailabilityModalDialog from "./EmployeeAvailabilityModalDialog";

function EditEmployeeAvailability(props: {
  employeeAvailability?: EmployeeAvailability;
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  onSuccess: (employeeAvailability: EmployeeAvailability) => void;
}) {
  const { employeeAvailability, isModalOpen, setIsModalOpen, onSuccess } =
    props;
  return (
    <div>
      <EmployeeAvailabilityModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        employeeAvailability={employeeAvailability}
        onSuccess={(employeeAvailability: EmployeeAvailability) => {
          onSuccess(employeeAvailability);
          setIsModalOpen(false);
        }}
      />
    </div>
  );
}

export default EditEmployeeAvailability;
