import { useState } from "react";
import { FormComponent } from "shared/types/FormComponent";
import Button from "../ui/Button";
import FormModalDialog from "./FormModalDialog";

const AddFormComponent = (props: {
  onSuccess: (component: FormComponent) => void;
}) => {
  const { onSuccess } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Button
        secondary
        type="button"
        onClick={() => setIsModalOpen(true)}
        className="text-lg !min-w-[40px]"
      >
        <div className="hidden px-4 lg:block">Add Form Component</div>
        <div className="lg:hidden">+</div>
      </Button>
      <FormModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSuccess={(component: FormComponent) => {
          onSuccess(component);
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default AddFormComponent;
