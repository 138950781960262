import TextField from "@/components/ui/TextField";
import { useState } from "react";
import { TextFieldFormComponent } from "shared/types/TextFieldFormComponent";

const TextFieldFormRenderer = (props: {
  component: TextFieldFormComponent;
  onUpdate?: (properties: TextFieldFormComponent) => void;
}) => {
  const { onUpdate } = props;
  const { elementId, labelText, value } = props.component;
  const [textFieldValue, setTextFieldValue] = useState(value ?? "");

  const onInput = (newValue: string) => {
    setTextFieldValue(newValue);
    const properties = {
      ...props.component,
      value: newValue,
    };
    if (onUpdate) {
      onUpdate(properties);
    }
  };

  return (
    <TextField
      elementId={elementId}
      labelText={labelText}
      value={textFieldValue}
      onInput={onInput}
    />
  );
};

export default TextFieldFormRenderer;
