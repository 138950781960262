import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";

/**
 * Logs an error message, optionally sending it to Sentry and displaying a toast notification.
 *
 * @param {any} error - The error to log.
 * @param {string} [message] - An optional message to display in the toast notification.
 * @param {boolean} [sendToSentry=true] - Whether to send the error to Sentry.
 * @return {void}
 */
export const logError = (
  error: any,
  message?: string,
  sendToSentry: boolean = true,
): void => {
  console.error(error);
  if (sendToSentry) {
    Sentry.captureMessage(error);
  }
  toast.error(message ?? "Uh oh! Something went wrong. Please try again.", {
    toastId: "error",
  });
};
