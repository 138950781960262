import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";

const storage = getStorage();

/**
 * Uploads a file from a data URL to the specified path in Firebase storage.
 * @param {string} path - The path where the file will be uploaded.
 * @param {string} dataUrl - The data URL of the file to be uploaded.
 * @return {Promise<void>} A promise that resolves when the upload is complete.
 */
export const uploadFileFromDataURL = async (path: string, dataUrl: string) => {
  const storageRef = ref(storage, path);
  await uploadString(storageRef, dataUrl, "data_url");
};

/**
 * Retrieves the download URL of a file from the specified path in Firebase storage.
 * @param {string} path - The path of the file.
 * @return {Promise<string | null>} A promise that resolves with the download URL or null if the path is empty.
 */
export const getFileURL = async (path: string): Promise<string | null> => {
  if (!path) return null;
  const storageRef = ref(storage, path);
  const url = await getDownloadURL(storageRef);
  return url;
};

/**
 * Deletes a file from the specified path in Firebase storage.
 * @param {string} filePath - The path of the file to be deleted.
 * @return {Promise<void>} A promise that resolves when the file is deleted.
 */
export const deleteFile = async (filePath: string) => {
  const fileRef = ref(storage, filePath);
  await deleteObject(fileRef);
};

/**
 * Checks if a file exists at the specified path in Firebase storage.
 * @param {string} filePath - The path of the file.
 * @return {Promise<boolean>} A promise that resolves with true if the file exists, otherwise false.
 */
export const fileExists = async (filePath: string): Promise<boolean> => {
  const fileRef = ref(storage, filePath);
  try {
    await getMetadata(fileRef);
    return true;
  } catch (error) {
    return false;
  }
};
