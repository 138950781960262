import { app } from "@/firebase";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore/lite";
import { Form } from "shared/types/Form";

const db = getFirestore(app);

/**
 * Updates the appointment form for a specific location.
 * @param {string} locationId - The ID of the location.
 * @param {Form} appointmentForm - The new appointment form data.
 * @return {Promise<void>} A promise that resolves when the update is complete.
 */
export const updateAppointmentForm = async (
  locationId: string,
  appointmentForm: Form,
): Promise<void> => {
  const locationDocRef = doc(db, "locations", locationId);
  const locationDoc = await getDoc(locationDocRef);
  if (!locationDoc.exists()) {
    throw new Error("Location not found");
  }
  const locationData = locationDoc.data();
  const updatedLocationData = {
    ...locationData,
    appointmentForm: appointmentForm,
  };
  await setDoc(locationDocRef, updatedLocationData);
};
