import Stripe from "stripe";
import { auth } from "../firebase";

const baseUrl = `https://${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`;

/**
 * Fetches the payment account details for a given accountId.
 * @param {string} stripeAccountId - The ID of the account.
 * @return {Promise<Stripe.Account>} - The payment account details.
 */
export const getPaymentAccount = async (stripeAccountId: string) => {
  const url = new URL(`${baseUrl}/getPaymentAccount`);
  url.searchParams.append("stripeAccountId", stripeAccountId);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
    },
  });
  const data = await response.json();
  return data as Stripe.Account;
};

/**
 * Creates a new payment account and returns the account ID.
 * @return {Promise<string>} - The ID of the created payment account.
 */
export const createPaymentAccount = async () => {
  const url = new URL(`${baseUrl}/createPaymentAccount`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
    },
  });
  const data = await response.json();
  return data.account as string;
};

/**
 * Links a payment account to a specific location.
 * @param {string} locationId - The ID of the location.
 * @param {string} stripeAccountId - The ID of the account.
 * @return {Promise<Stripe.AccountLink>} - The account link details.
 */
export const linkPaymentAccount = async (
  locationId: string,
  stripeAccountId: string,
) => {
  const url = new URL(`${baseUrl}/linkPaymentAccount`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      locationId: locationId,
      stripeAccountId: stripeAccountId,
    }),
  });
  const data = await response.json();
  return data as Stripe.AccountLink;
};

/**
 * Creates a checkout session for a customer with the specified details.
 * @param {string} locationId - The ID of the location.
 * @param {string} appointmentId - The ID of the appointment.
 * @param {string} customerFirstName - The first name of the customer.
 * @param {string} customerLastName - The last name of the customer.
 * @param {string} customerEmail - The email of the customer.
 * @param {string} customerPhone - The phone number of the customer.
 * @param {string} successUrl - The URL to redirect to upon successful payment.
 * @param {string} cancelUrl - The URL to redirect to upon payment cancellation.
 * @return {Promise<Stripe.Checkout.Session>} - The created checkout session.
 */
export const createCheckoutSession = async (
  locationId: string,
  appointmentId: string,
  customerFirstName: string,
  customerLastName: string,
  customerEmail: string,
  customerPhone: string,
  successUrl: string,
  cancelUrl: string,
) => {
  const url = new URL(`${baseUrl}/createCheckoutSession`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      locationId: locationId,
      appointmentId: appointmentId,
      customerFirstName: customerFirstName,
      customerLastName: customerLastName,
      customerEmail: customerEmail,
      customerPhone: customerPhone,
      successUrl: successUrl,
      cancelUrl: cancelUrl,
    }),
  });

  const data = await response.json();
  return data as Stripe.Checkout.Session;
};

/**
 * Refunds a payment for a given paymentIntent and accountId.
 * @param {string} paymentIntentId - The ID of the payment intent.
 * @param {string} locationId - The ID of the location.
 * @param {number} amount - The amount to refund.
 * @return {Promise<Stripe.Refund>} - The refund details.
 */
export const refundPayment = async (
  paymentIntentId: string,
  locationId: string,
  amount?: number,
) => {
  const url = new URL(`${baseUrl}/refundPayment`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      paymentIntentId: paymentIntentId,
      locationId: locationId,
      amount: amount,
    }),
  });
  const data = await response.json();
  return data as Stripe.Refund;
};
