import { getTimeFromDate } from "@/utils/Helpers";
import { useState } from "react";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { Employee } from "shared/types/Employee";
import { EmployeeTimeOff } from "shared/types/EmployeeTimeOff";
import AddEmployeeTimeOff from "./AddEmployeeTimeOff";
import EditEmployeeTimeOff from "./EditEmployeeTimeOff";

interface Props {
  employee: Employee;
  addEmployeeTimeOff: (employeeTimeOff: EmployeeTimeOff) => void;
  updateEmployeeTimeOff: (employeeTimeOff: EmployeeTimeOff) => void;
  removeEmployeeTimeOff: (employeeTimeOff: EmployeeTimeOff) => void;
}

const EmployeeTimeOffTable: React.FC<Props> = ({
  employee,
  addEmployeeTimeOff,
  updateEmployeeTimeOff,
  removeEmployeeTimeOff,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeTimeOff, setEmployeeTimeOff] = useState<
    EmployeeTimeOff | undefined
  >(undefined);

  const editEmployeeTimeOff = (employeeTimeOff: EmployeeTimeOff) => {
    setEmployeeTimeOff(employeeTimeOff);
    setIsModalOpen(true);
  };

  return (
    <>
      <EditEmployeeTimeOff
        employeeTimeOff={employeeTimeOff}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSuccess={(updatedTimeOff) => {
          updateEmployeeTimeOff(updatedTimeOff);
          setIsModalOpen(false);
        }}
      />
      <div className="mt-4 overflow-auto">
        <h2 className="mt-4 text-2xl text-monkey-purple">Time Off</h2>
        <AddEmployeeTimeOff
          onSuccess={(employeeTimeOff) => {
            addEmployeeTimeOff(employeeTimeOff);
          }}
        />
        <table className="min-w-full mt-2 border-collapse whitespace-nowrap">
          <thead>
            <tr>
              <th className="px-4 py-2 border border-gray-200">Start Date</th>
              <th className="px-4 py-2 border border-gray-200">Start Time</th>
              <th className="px-4 py-2 border border-gray-200">End Date</th>
              <th className="px-4 py-2 border border-gray-200">End Time</th>
              <th className="px-4 py-2" style={{ width: "100px" }}></th>
            </tr>
          </thead>
          <tbody>
            {employee.timeOff?.map((timeOff, index) => (
              <tr key={index}>
                <td className="px-4 py-2 border border-gray-200">
                  {new Date(timeOff.startDate).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {getTimeFromDate(new Date(timeOff.startDate))}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {new Date(timeOff.endDate).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {getTimeFromDate(new Date(timeOff.endDate))}
                </td>
                <td
                  className="flex items-center justify-center space-x-2"
                  style={{ width: "100px" }}
                >
                  <button
                    className="flex items-center justify-center w-8 h-12 text-monkey-purple"
                    onClick={() => editEmployeeTimeOff(timeOff)}
                    title="Edit"
                  >
                    <FaPencilAlt size={24} />
                  </button>
                  <button
                    className="flex items-center justify-center w-8 h-12 text-monkey-purple"
                    onClick={() => removeEmployeeTimeOff(timeOff)}
                    title="Delete"
                  >
                    <FaTrash size={24} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmployeeTimeOffTable;
