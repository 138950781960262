import { getDocsWithDates, getDocWithDates } from "@/utils/FirestoreHelpers";
import {
  getDayOfWeek,
  getHourFromTimeString,
  getMinuteFromTimeString,
} from "@/utils/Helpers";
import dayjs from "dayjs";
import {
  collection,
  doc,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { Appointment } from "shared/types/Appointment";
import { Product } from "shared/types/Product";
import { PaymentStatus } from "shared/utils/Enums";
import { v4 as uuidv4 } from "uuid";
import { app } from "../firebase";
import { getEmployeeById } from "./EmployeeService";

const db = getFirestore(app);

/**
 * Create a new appointment
 * @param {Appointment} appointment - The appointment to create
 * @return {Promise<void>}
 */
export const createAppointment = async (
  appointment: Appointment,
): Promise<void> => {
  if (!appointment.id) {
    appointment.id = uuidv4();
  }
  appointment.confirmed = undefined;
  appointment.paymentStatus = PaymentStatus.Unpaid;
  await setDoc(doc(db, "appointments", appointment.id), {
    ...appointment,
    created: new Date(),
    deleted: false,
  });
};

/**
 * Get an appointment by its ID
 * @param {string} appointmentId - The ID of the appointment to retrieve
 * @return {Promise<Appointment>}
 */
export const getAppointmentById = async (
  appointmentId: string,
): Promise<Appointment> => {
  const appointmentDoc = await getDocWithDates(
    doc(db, "appointments", appointmentId),
  );
  if (appointmentDoc.exists() && !appointmentDoc.data().deleted) {
    return appointmentDoc.data() as Appointment;
  } else {
    throw new Error("Appointment not found");
  }
};

/**
 * Get appointments by employee ID and optional date
 * @param {string} employeeId - The ID of the employee
 * @param {Date} [date] - The optional date to filter appointments
 * @return {Promise<Appointment[]>}
 */
export const getAppointmentsByEmployeeId = async (
  employeeId: string,
  date?: Date,
): Promise<Appointment[]> => {
  let startDate, endDate;
  if (date) {
    startDate = date.setHours(0, 0, 0, 0);
    endDate = date.setHours(23, 59, 59, 999);
  }
  const appointments = collection(db, "appointments");
  let q;
  if (date) {
    q = query(
      appointments,
      where("employee.id", "==", employeeId),
      where("paymentStatus", "!=", "unpaid"),
      where("date", ">=", startDate),
      where("date", "<=", endDate),
    );
  } else {
    q = query(
      appointments,
      where("employee.id", "==", employeeId),
      where("paymentStatus", "!=", "unpaid"),
    );
  }
  const querySnapshot = await getDocsWithDates(q);
  const appointmentsArray = [] as Appointment[];
  querySnapshot.forEach((doc) => {
    if (!doc.data().deleted) {
      const appointment = doc.data() as Appointment;
      appointmentsArray.push(appointment);
    }
  });
  return appointmentsArray;
};

/**
 * Get appointments by multiple employee IDs
 * @param {string[]} employeeIds - The IDs of the employees
 * @return {Promise<Appointment[]>}
 */
export const getAppointmentsByEmployeeIds = async (
  employeeIds: string[],
): Promise<Appointment[]> => {
  if (employeeIds.length === 0) {
    return [];
  }
  const appointmentsArray = [] as Appointment[];
  for (const employeeId of employeeIds) {
    const employeeAppointments = await getAppointmentsByEmployeeId(employeeId);
    appointmentsArray.push(...employeeAppointments);
  }
  return appointmentsArray;
};

/**
 * Update an existing appointment
 * @param {Appointment} appointment - The appointment to update
 * @return {Promise<void>}
 */
export const updateAppointment = async (
  appointment: Appointment,
): Promise<void> => {
  await setDoc(doc(db, "appointments", appointment.id), appointment);
};

/**
 * Soft delete an appointment by its ID
 * @param {string} appointmentId - The ID of the appointment to delete
 * @return {Promise<void>}
 */
export const deleteAppointmentById = async (
  appointmentId: string,
): Promise<void> => {
  const appointmentRef = doc(db, "appointments", appointmentId);
  await setDoc(appointmentRef, { deleted: true }, { merge: true });
};

/**
 * Get available appointment slots for an employee on a specific date for a given product
 * @param {string} employeeId - The ID of the employee
 * @param {Date} date - The date to check for available slots
 * @param {Product} product - The product for which the appointment is being made
 * @return {Promise<Date[]>}
 */
export const getAvailableAppointments = async (
  employeeId: string,
  date: Date,
  product: Product,
): Promise<Date[]> => {
  const employee = await getEmployeeById(employeeId);
  if (!employee) return [];

  const dayOfWeek = getDayOfWeek(date);
  const availabilities = employee.availability.filter((x) =>
    x.days.includes(dayOfWeek),
  );
  if (!availabilities.length) return [];

  const appointments = await getAppointmentsByEmployeeId(employeeId, date);
  let availableAppointments = [] as Date[];

  availabilities.forEach((availability) => {
    let start = dayjs(date)
      .hour(getHourFromTimeString(availability.startTime))
      .minute(getMinuteFromTimeString(availability.startTime));
    const end = dayjs(date)
      .hour(getHourFromTimeString(availability.endTime))
      .minute(getMinuteFromTimeString(availability.endTime));

    while (
      start.add(product.duration, "minutes").isBefore(end.add(1, "minute"))
    ) {
      availableAppointments.push(start.toDate());
      start = start.add(15, "minutes");
    }
  });

  const filterAppointments = (
    appointments: Date[],
    startDate: Date,
    endDate: Date,
  ): Date[] => {
    return appointments.filter(
      (availableAppointment) =>
        availableAppointment.getTime() <= startDate.getTime() ||
        availableAppointment.getTime() >= endDate.getTime(),
    );
  };

  appointments.forEach((appointment) => {
    const appointmentStartDate = appointment.date;
    const appointmentEndDate = dayjs(appointment.date)
      .add(product.duration, "minutes")
      .add(employee.timeBetweenAppointments, "minutes")
      .toDate();
    availableAppointments = filterAppointments(
      availableAppointments,
      appointmentStartDate,
      appointmentEndDate,
    );
  });

  employee.timeOff.forEach((timeOff) => {
    availableAppointments = filterAppointments(
      availableAppointments,
      timeOff.startDate,
      timeOff.endDate,
    );
  });

  return Array.from(
    new Set(availableAppointments.map((date) => date.getTime())),
  ).map((time) => new Date(time));
};
