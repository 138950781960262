import * as Sentry from "@sentry/react";

// Initialize Sentry
export const initializeSentry = () => {
  Sentry.init({
    enabled: import.meta.env.MODE !== "development",
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE),
    tracePropagationTargets:
      import.meta.env.VITE_SENTRY_PROPAGATION_TARGETS.split(";"),
    replaysSessionSampleRate: Number(
      import.meta.env.VITE_SENTRY_SESSION_SAMPLE_RATE,
    ),
    replaysOnErrorSampleRate: Number(
      import.meta.env.VITE_SENTRY_ERROR_SAMPLE_RATE,
    ),
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_APP_MONKEY_VERSION,
  });
};
