import ReauthenticateUser from "@/components/authentication/ReauthenticateUser";
import NavigationContainer from "@/components/layout/NavigationContainer";
import Button from "@/components/ui/Button";
import TextField from "@/components/ui/TextField";
import { logError } from "@/services/LoggingService";
import Store from "@/store";
import { updateEmail, updatePassword } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Account() {
  const navigate = useNavigate();
  const { user, setUser, setIsLoading } = Store();
  const [email, setEmail] = useState<string>(user?.email ?? "");
  const [password, setPassword] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const saveChanges = async () => {
    let success = true;

    // update email address
    if (user && email !== user.email) {
      setIsLoading(true);
      await updateEmail(user, email)
        .catch((error) => {
          success = false;
          logError(error);
        })
        .finally(() => setIsLoading(false));
      setUser({ ...user, email });
    }

    // update password
    if (user && password) {
      setIsLoading(true);
      await updatePassword(user, password)
        .catch((error) => {
          success = false;
          logError(error);
        })
        .finally(() => setIsLoading(false));
    }

    if (success) {
      toast.success("Account settings saved successfully.");
    }
  };

  return (
    <NavigationContainer>
      <ReauthenticateUser
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSuccess={saveChanges}
      />
      <div className="w-full max-w-screen-sm px-5 mx-auto mt-8">
        <h1 className="mb-8 text-3xl font-bold text-center text-monkey-purple">
          Account Settings
        </h1>
        <TextField
          type="email"
          elementId="email"
          labelText="Email"
          required={true}
          onInput={setEmail}
          value={email}
        />
        <TextField
          type="password"
          elementId="password"
          labelText="Password"
          onInput={setPassword}
          value={password}
        />
        <div className="flex justify-center space-x-4">
          <Button
            secondary
            type="button"
            className="w-[120px]"
            onClick={() => navigate("/locations")}
          >
            Back
          </Button>
          <Button
            primary
            type="submit"
            onClick={(e) => handleSubmit(e)}
            className="w-[120px]"
          >
            Save Changes
          </Button>
        </div>
      </div>
    </NavigationContainer>
  );
}

export default Account;
