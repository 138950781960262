import { useState } from "react";
import { EmployeeTimeOff } from "shared/types/EmployeeTimeOff";
import Button from "../ui/Button";
import EmployeeTimeOffModalDialog from "./EmployeeTimeOffModalDialog";

function AddEmployeeTimeOff(props: {
  onSuccess: (employeeTimeOff: EmployeeTimeOff) => void;
}) {
  const { onSuccess } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div>
      <Button
        secondary
        type="button"
        onClick={() => setIsModalOpen(true)}
        className="my-4 text-lg"
      >
        Add Time Off
      </Button>
      <EmployeeTimeOffModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        employeeTimeOff={null}
        onSuccess={(employeeTimeOff: EmployeeTimeOff) => {
          onSuccess(employeeTimeOff);
          setIsModalOpen(false);
        }}
      />
    </div>
  );
}

export default AddEmployeeTimeOff;
