import { FaArrowDown, FaArrowUp, FaPencil, FaTrash } from "react-icons/fa6";
import { FormComponent } from "shared/types/FormComponent";

function AppointmentFormComponents(props: {
  components: FormComponent[];
  editComponent: (component: FormComponent) => void;
  deleteComponent: (component: FormComponent) => void;
  reorderComponent: (
    component: FormComponent,
    direction: "up" | "down",
  ) => void;
}) {
  const { components, editComponent, deleteComponent, reorderComponent } =
    props;
  return (
    <div className="flex flex-col space-y-3">
      {components.map((component, index) => (
        <div
          key={index}
          className="flex p-2 text-white border-2 border-black rounded-lg bg-monkey-purple"
        >
          <div className="grow">{component.name}</div>
          <div className="flex items-center space-x-2 text-xl">
            {index !== 0 && (
              <FaArrowUp
                onClick={() => reorderComponent(component, "up")}
                className="hover:cursor-pointer"
              />
            )}
            {index !== components.length - 1 && (
              <FaArrowDown
                onClick={() => reorderComponent(component, "down")}
                className="hover:cursor-pointer"
              />
            )}
            <FaPencil
              onClick={() => editComponent(component)}
              className="hover:cursor-pointer"
            />
            <FaTrash
              onClick={() => deleteComponent(component)}
              className="hover:cursor-pointer"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default AppointmentFormComponents;
