import { Form } from "shared/types/Form";
import { FormComponent } from "shared/types/FormComponent";
import { MultiSelectFormComponent } from "shared/types/MultiSelectFormComponent";
import { TextFieldFormComponent } from "shared/types/TextFieldFormComponent";
import { FormComponentType } from "shared/utils/Enums";
import MultiSelectFormRenderer from "./MultiSelectFormRenderer";
import TextFieldFormRenderer from "./TextFieldFormRenderer";

const FormRenderer = (props: {
  form: Form;
  onUpdate?: (form: Form) => void;
}) => {
  const { form, onUpdate } = props;

  const updateFormComponent = (index: number, formComponent: FormComponent) => {
    const components = [...form.components];
    components[index] = formComponent;
    if (onUpdate) {
      onUpdate({ ...form, components });
    }
  };

  return (
    <div>
      {form.components.map((component, index) => (
        <div key={index}>
          {component.type === FormComponentType.TextField && (
            <TextFieldFormRenderer
              component={component as TextFieldFormComponent}
              onUpdate={(component: TextFieldFormComponent) =>
                updateFormComponent(index, component)
              }
            />
          )}
          {component.type === FormComponentType.MultiSelect && (
            <MultiSelectFormRenderer
              component={component as MultiSelectFormComponent}
              onUpdate={(component: MultiSelectFormComponent) =>
                updateFormComponent(index, component)
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default FormRenderer;
