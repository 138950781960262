import { useState } from "react";
import { Employee } from "shared/types/Employee";
import Button from "../ui/Button";
import AddEmployeeModalDialog from "./AddEmployeeModalDialog";

const AddEmployee = (props: { onSuccess: (employee: Employee) => void }) => {
  const { onSuccess } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div>
      <Button
        secondary
        type="button"
        onClick={() => setIsModalOpen(true)}
        className="text-lg"
      >
        Add Employee
      </Button>
      <AddEmployeeModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSuccess={(employee: Employee) => {
          onSuccess(employee);
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};

export default AddEmployee;
