import { useState } from "react";
import { FormComponent } from "shared/types/FormComponent";
import { TextFieldFormComponent } from "shared/types/TextFieldFormComponent";
import Button from "../ui/Button";
import TextField from "../ui/TextField";

const AddTextFieldFormComponent = (props: {
  component?: TextFieldFormComponent;
  onSuccess: (component: FormComponent) => void;
  onCancel: () => void;
}) => {
  const { onSuccess, onCancel, component } = props;
  const [componentName, setComponentName] = useState(component?.name || "");
  const [textFieldName, setTextFieldName] = useState(
    component?.labelText || "",
  );

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (component) {
      onSuccess(component);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <TextField
        elementId="component-name"
        labelText="Component name"
        onInput={setComponentName}
        value={componentName}
        required={true}
      />
      <TextField
        elementId="text-field-name"
        labelText="Text field name"
        onInput={setTextFieldName}
        value={textFieldName}
        required={true}
      />
      <div className="flex items-center justify-center space-x-5">
        <Button secondary type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button primary type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

export default AddTextFieldFormComponent;
