import RecoverEmail from "@/components/authentication/RecoverEmail";
import VerifyEmail from "@/components/authentication/VerifyEmail";
import ResetPassword from "../../components/authentication/ResetPassword";
import { auth } from "../../firebase";
import { getParameterByName } from "../../utils/Helpers";

const AuthHandler = () => {
  const mode = getParameterByName("mode");
  const actionCode = getParameterByName("oobCode");
  const continueUrl = getParameterByName("continueUrl");
  const lang = getParameterByName("lang") || "en";

  // Handle the user management action.
  switch (mode) {
    case "resetPassword":
      return (
        <ResetPassword
          auth={auth}
          actionCode={actionCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      );
    case "recoverEmail":
      return (
        <RecoverEmail
          auth={auth}
          actionCode={actionCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      );
    case "verifyEmail":
      return (
        <VerifyEmail
          auth={auth}
          actionCode={actionCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      );
    default:
      throw new Error(`AuthHandler: Unrecognized mode - ${mode}`);
  }
};

export default AuthHandler;
