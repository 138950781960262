import { logError } from "@/services/LoggingService";
import { sendEmailVerification } from "firebase/auth";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import Footer from "../layout/Footer";

const SignUp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start("#firebaseui-auth-container", {
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      tosUrl: "https://app.appointmentmonkey.com/terms",
      privacyPolicyUrl: "https://app.appointmentmonkey.com/privacy",
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          if (authResult.additionalUserInfo.isNewUser) {
            sendEmailVerification(authResult.user);
          }
          navigate("/locations");
          return false;
        },
        signInFailure: (error) => {
          logError(error);
        },
      },
    });
  }, []);

  return (
    <div className="flex flex-col h-dvh">
      <div className="w-3/4 px-6">
        <img src="/images/logos/3.png" alt="Appointment Monkey" />
      </div>
      <div className="mt-16 text-3xl text-center">Hey there 👋</div>
      <div id="firebaseui-auth-container"></div>
      <div className="flex-grow"></div>
      <Footer />
    </div>
  );
};

export default SignUp;
