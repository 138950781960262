import { FormComponent } from "shared/types/FormComponent";
import FormModalDialog from "./FormModalDialog";

const EditFormComponent = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  component?: FormComponent;
  onSuccess: (component: FormComponent) => void;
}) => {
  const { isModalOpen, setIsModalOpen, component, onSuccess } = props;
  return (
    <FormModalDialog
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      component={component}
      onSuccess={(component: FormComponent) => {
        onSuccess(component);
        setIsModalOpen(false);
      }}
    />
  );
};

export default EditFormComponent;
