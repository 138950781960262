import { createAppointment } from "@/services/AppointmentService";
import { E164Number } from "libphonenumber-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Appointment } from "shared/types/Appointment";
import Button from "../ui/Button";
import PhoneNumber from "../ui/PhoneNumber";
import TextField from "../ui/TextField";

function AppointmentBookingCustomer(props: {
  appointment: Appointment;
  onBack: (appointment: Appointment) => void;
}) {
  const navigate = useNavigate();
  const { appointment, onBack } = props;
  const [updatedAppointment, setUpdatedAppointment] = useState(appointment);
  const [appointmentCustomer, setAppointmentCustomer] = useState(
    appointment.customer || {},
  );
  return (
    <div className="flex justify-center">
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          appointment.customer = appointmentCustomer;
          await createAppointment(appointment);
          navigate(`/summary/${appointment.id}`);
        }}
      >
        <div className="mt-6 text-center">Enter your contact information</div>
        <div>
          <TextField
            elementId="customer-first-name"
            labelText="First name"
            required={true}
            onInput={(value: string) =>
              setAppointmentCustomer({
                ...appointmentCustomer,
                firstName: value,
              })
            }
            defaultValue={appointmentCustomer.firstName}
            autoFocus={true}
          />
        </div>
        <div>
          <TextField
            elementId="customer-last-name"
            labelText="Last name"
            required={true}
            onInput={(value: string) =>
              setAppointmentCustomer({
                ...appointmentCustomer,
                lastName: value,
              })
            }
            defaultValue={appointmentCustomer.lastName}
          />
        </div>
        <div>
          <TextField
            type="email"
            elementId="customer-email-address"
            labelText="Email"
            required={true}
            onInput={(value: string) =>
              setAppointmentCustomer({ ...appointmentCustomer, email: value })
            }
            defaultValue={appointmentCustomer.email}
          />
        </div>
        <div>
          <PhoneNumber
            elementId="customer-phone-number"
            labelText="Phone number"
            required={false}
            onChange={(value: E164Number | undefined) =>
              setAppointmentCustomer({
                ...appointmentCustomer,
                phone: value as string,
              })
            }
            value={appointmentCustomer.phone as E164Number}
          />
        </div>
        <div className="flex justify-center mt-4 space-x-4">
          <Button
            secondary
            type="button"
            onClick={() => {
              setUpdatedAppointment({
                ...updatedAppointment,
                customer: appointmentCustomer,
              });
              onBack(updatedAppointment);
            }}
          >
            Back
          </Button>
          <Button primary type="submit">
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AppointmentBookingCustomer;
