import { Timestamp, getDoc, getDocs } from "firebase/firestore/lite";

/**
 * Converts Firestore Timestamps to JavaScript Date objects.
 * @param {any} obj - The object to convert.
 * @return {any} - The converted object with Dates instead of Timestamps.
 */
export const convertTimestampsToDates = (obj: any): any => {
  if (obj instanceof Timestamp) {
    return obj.toDate();
  } else if (Array.isArray(obj)) {
    return obj.map(convertTimestampsToDates);
  } else if (obj !== null && typeof obj === "object") {
    const newObj: any = {};
    for (const key in obj) {
      newObj[key] = convertTimestampsToDates(obj[key]);
    }
    return newObj;
  }
  return obj;
};

/**
 * Retrieves a Firestore document and converts any Timestamps to Dates.
 * @param {any} docRef - The reference to the Firestore document.
 * @return {Promise<any>} - The document data with Dates instead of Timestamps.
 */
export const getDocWithDates = async (docRef: any) => {
  const docSnapshot = await getDoc(docRef);
  const data = docSnapshot.data();
  return data ? convertTimestampsToDates(data) : null;
};

/**
 * Retrieves Firestore documents from a query and converts any Timestamps to Dates.
 * @param {any} query - The Firestore query.
 * @return {Promise<any[]>} - An array of document data with Dates instead of Timestamps.
 */
export const getDocsWithDates = async (query: any) => {
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc: any) => ({
    id: doc.id,
    ...convertTimestampsToDates(doc.data()),
  }));
};
