import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import PageNotFound from "./PageNotFound";

function RouterError() {
  const error = useRouteError();
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <PageNotFound />;
    } else {
      errorMessage = `${error.status}: ${error.statusText}`;
    }
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = "Unknown error";
  }

  return (
    <div>
      {import.meta.env.MODE !== "production" && (
        <div>
          <h1>You have encountered an error</h1>
          <p>{errorMessage}</p>
        </div>
      )}
      {import.meta.env.MODE === "production" && (
        <div>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred. Please try again.</p>
        </div>
      )}
    </div>
  );
}

export default RouterError;
