import { Navigate } from "react-router-dom";
import Store from "../../store";

const PrivateRoute = ({ children }: { children: any }) => {
  const { user } = Store();
  if (user) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
