function SentryError(props: {
  error: any;
  componentStack: any;
  resetError: any;
}) {
  const { error, componentStack, resetError } = props;
  return (
    <div>
      {import.meta.env.MODE !== "production" && (
        <div>
          <h1>You have encountered an error</h1>
          <p>{error.toString()}</p>
          <p>{componentStack}</p>
          <button
            onClick={() => {
              resetError();
            }}
          >
            Click here to reset!
          </button>
        </div>
      )}
      {import.meta.env.MODE === "production" && (
        <div>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred. Please try again.</p>
        </div>
      )}
    </div>
  );
}

export default SentryError;
