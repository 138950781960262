import { isValidTime } from "@/utils/Helpers";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EmployeeAvailability } from "shared/types/EmployeeAvailability";
import { DayOfWeek } from "shared/utils/Enums";
import { v4 as uuidv4 } from "uuid";
import Button from "../ui/Button";
import { Checkbox } from "../ui/Checkbox";
import Label from "../ui/Label";
import ModalDialog from "../ui/ModalDialog";
import TimeInput from "../ui/TimeInput";

const EmployeeAvailabilityModalDialog = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  employeeAvailability?: EmployeeAvailability | null;
  onSuccess: (employeeAvailability: EmployeeAvailability) => void;
}) => {
  const { isModalOpen, setIsModalOpen, employeeAvailability, onSuccess } =
    props;
  const [days, setDays] = useState(employeeAvailability?.days || []);
  const [startTime, setStartTime] = useState(
    employeeAvailability?.startTime || "",
  );
  const [startTimeIsValid, setStartTimeIsValid] = useState(false);
  const [endTime, setEndTime] = useState(employeeAvailability?.endTime || "");
  const [endTimeIsValid, setEndTimeIsValid] = useState(false);

  useEffect(() => {
    setDays(employeeAvailability?.days || []);
    setStartTime(employeeAvailability?.startTime || "");
    if (
      employeeAvailability?.startTime &&
      isValidTime(employeeAvailability.startTime)
    ) {
      setStartTimeIsValid(true);
    }
    setEndTime(employeeAvailability?.endTime || "");
    if (
      employeeAvailability?.endTime &&
      isValidTime(employeeAvailability.endTime)
    ) {
      setEndTimeIsValid(true);
    }
  }, [isModalOpen, employeeAvailability]);

  const toggleDay = (day: DayOfWeek) => {
    const index = days.indexOf(day);
    if (index > -1) {
      setDays([...days.slice(0, index), ...days.slice(index + 1)]);
    } else {
      setDays([...days, day]);
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!startTimeIsValid) {
      toast.error("Please enter a valid start time.", {
        toastId: "error",
      });
    } else if (!endTimeIsValid) {
      toast.error("Please enter a valid end time.", {
        toastId: "error",
      });
    } else if (!days.length) {
      toast.error("Please select at least one day.", {
        toastId: "error",
      });
    } else {
      const newEmployeeAvailability = {
        id: employeeAvailability?.id || uuidv4(),
        days: days,
        startTime: startTime,
        endTime: endTime,
      };
      onSuccess(newEmployeeAvailability);
      setIsModalOpen(false);
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div className="mb-2 text-4xl text-center text-monkey-purple">
        Add Availability
      </div>
      <form onSubmit={onSubmit}>
        <div className="flex flex-wrap justify-center my-4 space-x-4">
          {Object.values(DayOfWeek).map((day) => (
            <div key={day} className="flex items-center my-2 space-x-2">
              <Checkbox
                id={day.toLowerCase()}
                checked={days.includes(day)}
                onCheckedChange={() => toggleDay(day)}
              />
              <Label htmlFor={day.toLowerCase()} className="!text-lg !my-auto">
                {day.charAt(0).toUpperCase() + day.slice(1).toLowerCase()}
              </Label>
            </div>
          ))}
        </div>
        <div>
          <TimeInput
            elementId="start-time"
            labelText="Start time"
            time={startTime}
            setTime={setStartTime}
            setIsValid={setStartTimeIsValid}
            required={true}
            placeholder="8:00 AM"
          />
        </div>
        <div>
          <TimeInput
            elementId="end-time"
            labelText="End time"
            time={endTime}
            setTime={setEndTime}
            setIsValid={setEndTimeIsValid}
            required={true}
            placeholder="8:00 AM"
          />
        </div>
        <div className="flex items-center justify-center space-x-5">
          <Button secondary type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button primary type="submit">
            Save
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default EmployeeAvailabilityModalDialog;
