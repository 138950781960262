import { app, auth } from "@/firebase";
import { doc, getDoc, getFirestore } from "firebase/firestore/lite";
import { User } from "shared/types/User";

const db = getFirestore(app);
const baseUrl = `https://${import.meta.env.VITE_FIREBASE_AUTH_DOMAIN}`;

/**
 * Retrieves a user by their ID.
 * @param {string} [userId] - The ID of the user.
 * @return {Promise<User | null>} - The user data or null if not found.
 * @throws {Error} - If the user ID is undefined.
 */
export const getUserById = async (userId?: string): Promise<User | null> => {
  if (!userId) {
    throw new Error("User ID is undefined");
  }
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    return null;
  }
  const userData = docSnap.data() as User | undefined;
  return userData ?? null;
};

/**
 * Verifies the user's email.
 * @return {Promise<boolean>} - True if the email verification is successful, false otherwise.
 */
export const verifyUserEmail = async () => {
  const url = new URL(`${baseUrl}/verifyUserEmail`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
    },
  });
  return response.ok;
};
