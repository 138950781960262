import { verifyUserEmail } from "@/services/UserService";
import { applyActionCode, Auth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../ui/Button";

function handleVerifyEmail(
  auth: Auth,
  actionCode: string,
  setEmailVerifyConfirmationMessage: (message: string) => void,
) {
  applyActionCode(auth, actionCode)
    .then(() => {
      verifyUserEmail();
      setEmailVerifyConfirmationMessage(
        "Your email address has been successfully verified.",
      );
    })
    .catch((error) => {
      console.error(error);
      setEmailVerifyConfirmationMessage(
        "Uh oh! Your email verification code is invalid or expired. Please try again.",
      );
    });
}

const VerifyEmail = ({
  auth,
  actionCode,
}: {
  auth: Auth;
  actionCode: string;
  continueUrl: string;
  lang: string;
}) => {
  const [emailVerifyConfirmationMessage, setEmailVerifyConfirmationMessage] =
    useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    handleVerifyEmail(auth, actionCode, setEmailVerifyConfirmationMessage);
  }, [auth, actionCode]);

  return (
    <div className="w-full max-w-screen-sm px-5 mx-auto mt-8 text-center">
      <div className="mb-3">{emailVerifyConfirmationMessage}</div>
      <Button primary type="button" onClick={() => navigate("/")}>
        Return to home screen
      </Button>
    </div>
  );
};

export default VerifyEmail;
