export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Paid"] = "paid";
    PaymentStatus["Unpaid"] = "unpaid";
    PaymentStatus["Refund"] = "refund";
    PaymentStatus["PartialRefund"] = "partial_refund";
    PaymentStatus["PaymentRequired"] = "payment_required";
    PaymentStatus["NoPaymentRequired"] = "no_payment_required";
})(PaymentStatus || (PaymentStatus = {}));
export var ChargeCustomers;
(function (ChargeCustomers) {
    ChargeCustomers["Automatically"] = "automatically";
    ChargeCustomers["Manually"] = "manually";
})(ChargeCustomers || (ChargeCustomers = {}));
export var FormComponentType;
(function (FormComponentType) {
    FormComponentType["TextField"] = "text_field";
    FormComponentType["MultiSelect"] = "multi_select";
})(FormComponentType || (FormComponentType = {}));
export var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek["Monday"] = "monday";
    DayOfWeek["Tuesday"] = "tuesday";
    DayOfWeek["Wednesday"] = "wednesday";
    DayOfWeek["Thursday"] = "thursday";
    DayOfWeek["Friday"] = "friday";
    DayOfWeek["Saturday"] = "saturday";
    DayOfWeek["Sunday"] = "sunday";
})(DayOfWeek || (DayOfWeek = {}));
export var DurationUnits;
(function (DurationUnits) {
    DurationUnits["Minutes"] = "minutes";
    DurationUnits["Hours"] = "hours";
    DurationUnits["Days"] = "days";
})(DurationUnits || (DurationUnits = {}));
