import AppointmentDetail from "@/components/appointments/AppointmentDetails";
import AppointmentEmployeeFilter from "@/components/appointments/AppointmentEmployeeFilter";
import NavigationContainer from "@/components/layout/NavigationContainer";
import LocationSidebar from "@/components/locations/LocationSidebar";
import { getAppointmentsByEmployeeIds } from "@/services/AppointmentService";
import { getLocationById } from "@/services/LocationService";
import { logError } from "@/services/LoggingService";
import { getFileURL } from "@/services/StorageService";
import Store from "@/store";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import { useParams } from "react-router-dom";
import { Employee } from "shared/types/Employee";
import { Event } from "shared/types/Event";
import { Location } from "shared/types/Location";
import { auth } from "../../firebase";

const AppointmentSchedule = () => {
  const user = auth?.currentUser;
  const { setIsLoading } = Store();
  const { locationId } = useParams();
  const localizer = dayjsLocalizer(dayjs);
  const [events, setEvents] = useState<Event[]>([]);
  const [location, setLocation] = useState<Location | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId!);
      setLocation(location);
      if (location.employees.length) {
        location.employees = await Promise.all(
          location.employees.map(async (employee) => {
            employee.image = (await getFileURL(employee.image)) ?? "";
            return employee;
          }),
        );
        const employee =
          location.employees.filter((e) => e.email === user?.email)?.[0] ??
          location.employees[0];
        setSelectedEmployees([...selectedEmployees, employee]);
      }
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const appointments = await getAppointmentsByEmployeeIds(
        selectedEmployees.length > 0
          ? selectedEmployees.map((employee) => employee.id)
          : location!.employees.map((employee) => employee.id),
      );
      const events = appointments.map((appointment) => ({
        appointment: appointment,
        location: location!,
        start: appointment.date,
        end: dayjs(appointment.date).add(1, "hour").toDate(),
        title: `${appointment.customer.firstName} ${appointment.customer.lastName}`,
      }));
      setEvents(events);
    };
    if (location !== null) {
      fetchData().catch((error) => logError(error));
    }
  }, [selectedEmployees]);

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId!} />}>
      <div className="text-4xl text-monkey-purple">Appointments</div>
      {selectedEvent ? (
        <AppointmentDetail
          event={selectedEvent}
          cancel={() => setSelectedEvent(null)}
        />
      ) : (
        <>
          <AppointmentEmployeeFilter
            employees={location?.employees ?? []}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
          />
          <Calendar
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            events={events}
            style={{ height: "100vh" }}
            defaultView="day"
            min={dayjs(new Date()).set("hour", 8).set("minute", 0).toDate()} // 8:00 AM
            max={dayjs(new Date()).set("hour", 17).set("minute", 0).toDate()} // 5:00 PM
            onSelectEvent={(event) => setSelectedEvent(event)}
          />
        </>
      )}
    </NavigationContainer>
  );
};

export default AppointmentSchedule;
