import TextField from "./TextField";

const partialTimeFormat =
  /^$|^(1[0-2]|[1-9])(:[0-5]?[0-9]?)?( (A|P|AM|PM)?)?$/i;
const completeTimeFormat = /^(1[0-2]|[1-9]):[0-5][0-9] (AM|PM)$/i;

const handleBackspace = (
  value: string,
  event: React.FormEvent<HTMLInputElement>,
) => {
  const inputEvent = event.nativeEvent as InputEvent;
  if (inputEvent.inputType === "deleteContentBackward") {
    if (value.endsWith(":")) {
      value = value.slice(0, -1);
    }
    value = value.slice(0, -1);
    value = autoInsertSpace(value);
  }
  return value;
};

const autoInsertColon = (value: string) => {
  if (/^\d{4}[AP]M$/i.test(value)) {
    return value.slice(0, 2) + ":" + value.slice(2);
  } else if (/^\d{2}$/.test(value) && parseInt(value, 10) <= 12) {
    return value.slice(0, 2) + ":" + value.slice(2);
  } else if (/^\d$/.test(value) && parseInt(value, 10) > 1) {
    return value + ":";
  } else if (value.length === 2 && value[1] !== ":") {
    return value.slice(0, 1) + ":" + value.slice(1);
  } else if (value.length === 3 && value.indexOf(":") === -1) {
    return value.slice(0, 2) + ":" + value.slice(2);
  }
  return value;
};

const autoInsertSpace = (value: string) => {
  if (/^(1[0-2]|[1-9]):[0-5][0-9][AP]M$/i.test(value)) {
    return value.slice(0, 5) + " " + value.slice(5);
  } else if (
    value.includes(":") &&
    value.split(":")[1].length === 2 &&
    !value.includes(" ")
  ) {
    return value + " ";
  } else if (
    value.includes(":") &&
    (value.split(":")[1].length !== 2 || value.endsWith(" "))
  ) {
    return value.replace(/ $/, "");
  }
  return value;
};

const autoInsertM = (value: string) => {
  if (
    value?.toUpperCase().endsWith("A") ||
    value?.toUpperCase().endsWith("P")
  ) {
    return value + "M";
  }
  return value;
};

const ensureValidMinutes = (value: string) => {
  if (value.includes(":")) {
    const minutesPart = value.split(":")[1];
    if (minutesPart.length > 0 && parseInt(minutesPart[0], 10) > 5) {
      return value.slice(0, -1);
    }
  }
  return value;
};

const allowManualSpace = (
  value: string,
  event: React.FormEvent<HTMLInputElement>,
) => {
  const inputEvent = event.nativeEvent as InputEvent;
  if (
    value.includes(":") &&
    value.split(":")[1].length === 2 &&
    !value.includes(" ") &&
    inputEvent.inputType === "insertText" &&
    inputEvent.data === " "
  ) {
    return value + " ";
  }
  return value;
};

const onInput = (
  value: string,
  event: React.FormEvent<HTMLInputElement>,
  setTime: (time: string) => void,
  setIsValid: (isValid: boolean) => void,
) => {
  value = handleBackspace(value, event);
  value = autoInsertColon(value);
  value = autoInsertSpace(value);
  value = autoInsertM(value);
  value = ensureValidMinutes(value);
  value = allowManualSpace(value, event);

  if (partialTimeFormat.test(value)) {
    value = value?.toUpperCase();
    setTime(value);
    if (completeTimeFormat.test(value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }
};

const TimeInput = (props: {
  elementId: string;
  labelText: string;
  time: string;
  setTime: (time: string) => void;
  setIsValid: (isValid: boolean) => void;
  required?: boolean;
  className?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  placeholder?: string;
}) => {
  const {
    elementId,
    labelText,
    time,
    setTime,
    setIsValid,
    required,
    className,
    autoFocus,
    defaultValue,
    placeholder,
  } = props;

  return (
    <div>
      <TextField
        elementId={elementId}
        labelText={labelText}
        type="text"
        value={time}
        required={required}
        className={className}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onInput={(value, event) => onInput(value, event, setTime, setIsValid)}
      />
    </div>
  );
};

export default TimeInput;
