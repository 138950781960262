import { E164Number } from "libphonenumber-js";
import React from "react";
import PhoneInput from "react-phone-number-input";
import Label from "./Label";

const PhoneNumber = React.forwardRef(
  (
    props: {
      elementId: string;
      labelText: string;
      className?: string;
      required?: boolean;
      autoFocus?: boolean;
      value: E164Number | undefined;
      onChange: (value: E164Number | undefined) => void;
    },
    ref: any,
  ) => {
    const { elementId, labelText, className, autoFocus, value, onChange } =
      props;
    const required = props.required === undefined ? false : props.required;
    return (
      <div className="w-full mb-5">
        <Label htmlFor={elementId} className="text-left">
          {required ? labelText + "*" : labelText}
        </Label>
        <PhoneInput
          ref={ref}
          id={elementId}
          rules={{ required: required }}
          autoFocus={autoFocus}
          value={value}
          onChange={onChange}
          defaultCountry="US"
          className={`w-full h-10 p-2 text-base border-2 rounded-lg border-monkey-purple border:focus-monkey-purple focus:outline-none focus:ring-2 focus:ring-monkey-purple focus:border-monkey-purple ${className ?? ""}`.trim()}
        />
      </div>
    );
  },
);

export default PhoneNumber;
