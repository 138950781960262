import { FiPlusCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const AddLocationTile = () => {
  const navigate = useNavigate();
  const handleNavigate = (path: string) => () => navigate(path);
  return (
    <div className="flex flex-col items-center w-[250px] m-6">
      <div
        className="relative w-[250px] h-[250px] m-6 bg-monkey-gray cursor-pointer flex justify-center items-center"
        onClick={handleNavigate("/locations/new")}
      >
        <FiPlusCircle className="text-[120px] text-white" />
      </div>
      <div className="text-2xl text-center">Add Location</div>
    </div>
  );
};

export default AddLocationTile;
