import { logError } from "@/services/LoggingService";
import Store from "@/store";
import { getAuth, reauthenticateWithCredential } from "firebase/auth";
import firebase from "firebase/compat/app";
import { useState } from "react";
import Button from "../ui/Button";
import ModalDialog from "../ui/ModalDialog";
import TextField from "../ui/TextField";

function ReauthenticateUser(props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  onSuccess: () => void;
}) {
  const { setIsLoading } = Store();
  const [password, setPassword] = useState<string>("");
  const { isModalOpen, setIsModalOpen, onSuccess } = props;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const currentUser = getAuth().currentUser!;
    const credentials = firebase.auth.EmailAuthProvider.credential(
      currentUser.email!,
      password,
    );
    let success = true;
    await reauthenticateWithCredential(currentUser, credentials)
      .catch((error) => {
        success = false;
        setPassword("");
        logError(error, "Invalid password. Please try again.");
      })
      .finally(() => setIsLoading(false));
    if (success) {
      setIsModalOpen(false);
      onSuccess();
    }
  };

  return (
    <ModalDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <form onSubmit={handleSubmit}>
        <div className="my-4">
          To confirm it's you, please enter your password below.
        </div>
        <TextField
          type="password"
          elementId="password"
          labelText="Password"
          onInput={setPassword}
          value={password}
          autoFocus={true}
          required={true}
        />
        <div className="flex justify-center">
          <Button primary type="submit">
            Submit
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
}

export default ReauthenticateUser;
