import { getTimeFromDate, isValidTime, setDateTime } from "@/utils/Helpers";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EmployeeTimeOff } from "shared/types/EmployeeTimeOff";
import { v4 as uuidv4 } from "uuid";
import Button from "../ui/Button";
import Calendar from "../ui/Calendar";
import Label from "../ui/Label";
import ModalDialog from "../ui/ModalDialog";
import TimeInput from "../ui/TimeInput";

const EmployeeTimeOffModalDialog = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  employeeTimeOff?: EmployeeTimeOff | null;
  onSuccess: (employeeTimeOff: EmployeeTimeOff) => void;
}) => {
  const { isModalOpen, setIsModalOpen, employeeTimeOff, onSuccess } = props;
  const [startDate, setStartDate] = useState(employeeTimeOff?.startDate);
  const [startTime, setStartTime] = useState(
    employeeTimeOff?.startDate
      ? getTimeFromDate(employeeTimeOff.startDate)
      : "",
  );
  const [startTimeIsValid, setStartTimeIsValid] = useState(false);
  const [endDate, setEndDate] = useState(employeeTimeOff?.endDate);
  const [endTime, setEndTime] = useState(
    employeeTimeOff?.endDate ? getTimeFromDate(employeeTimeOff.endDate) : "",
  );
  const [endTimeIsValid, setEndTimeIsValid] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    setStep(1);
    setStartDate(employeeTimeOff?.startDate);
    setStartTime(
      employeeTimeOff?.startDate
        ? getTimeFromDate(employeeTimeOff.startDate)
        : "",
    );
    if (
      employeeTimeOff?.startDate &&
      isValidTime(getTimeFromDate(employeeTimeOff.startDate))
    ) {
      setStartTimeIsValid(true);
    }
    setEndDate(employeeTimeOff?.endDate);
    setEndTime(
      employeeTimeOff?.endDate ? getTimeFromDate(employeeTimeOff.endDate) : "",
    );
    if (
      employeeTimeOff?.endDate &&
      isValidTime(getTimeFromDate(employeeTimeOff.endDate))
    ) {
      setEndTimeIsValid(true);
    }
  }, [isModalOpen, employeeTimeOff]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (step === 1) {
      if (!startDate) {
        toast.error("Please enter a valid start date.", {
          toastId: "error",
        });
      } else if (!startTimeIsValid) {
        toast.error("Please enter a valid start time.", {
          toastId: "error",
        });
      } else {
        setStep(2);
      }
    } else {
      if (!endDate) {
        toast.error("Please enter a valid end date.", {
          toastId: "error",
        });
      } else if (!endTimeIsValid) {
        toast.error("Please enter a valid end time.", {
          toastId: "error",
        });
      } else {
        const newEmployeeTimeOff = {
          id: employeeTimeOff?.id || uuidv4(),
          startDate: startDate ? setDateTime(startDate, startTime) : new Date(),
          endDate: endDate ? setDateTime(endDate, endTime) : new Date(),
        };
        onSuccess(newEmployeeTimeOff);
        setIsModalOpen(false);
      }
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onBack = () => {
    setStep(1);
  };

  return (
    <ModalDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div className="mb-4 text-4xl text-center text-monkey-purple">
        Add Time Off
      </div>
      <form onSubmit={onSubmit} className="space-y-4">
        {step === 1 && (
          <div className="flex items-center justify-center">
            <div className="max-w-[300px] space-y-4">
              <Label htmlFor="start-date">Start date*</Label>
              <Calendar
                mode="single"
                selected={startDate}
                onSelect={(date) => setStartDate(date)}
                className="border rounded-md"
                required={true}
              />
              <TimeInput
                elementId="start-time"
                labelText="Start time"
                time={startTime}
                setTime={setStartTime}
                setIsValid={setStartTimeIsValid}
                required={true}
                placeholder="8:00 AM"
              />
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="flex items-center justify-center">
            <div className="max-w-[300px] space-y-4">
              <Label htmlFor="end-date">End date*</Label>
              <Calendar
                mode="single"
                selected={endDate}
                onSelect={(date) => setEndDate(date)}
                className="border rounded-md"
                required={true}
              />
              <TimeInput
                elementId="end-time"
                labelText="End time"
                time={endTime}
                setTime={setEndTime}
                setIsValid={setEndTimeIsValid}
                required={true}
                placeholder="5:00 PM"
              />
            </div>
          </div>
        )}
        <div className="flex items-center justify-center space-x-5">
          {step === 1 && (
            <Button secondary type="button" onClick={onCancel}>
              Cancel
            </Button>
          )}
          {step === 2 && (
            <Button secondary type="button" onClick={onBack}>
              Back
            </Button>
          )}
          <Button primary type="submit">
            {step === 1 ? "Next" : "Save"}
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
};

export default EmployeeTimeOffModalDialog;
