import MultiSelect from "@/components/ui/MultiSelect";
import { MultiSelectFormComponent } from "shared/types/MultiSelectFormComponent";

const MultiSelectFormRenderer = (props: {
  component: MultiSelectFormComponent;
  onUpdate?: (component: MultiSelectFormComponent) => void;
}) => {
  const { onUpdate } = props;
  const { prompt, items, selectedItems } = props.component;

  const onChange = (newSelectedItems: string[]) => {
    const properties = {
      ...props.component,
      selectedItems: newSelectedItems,
    };
    if (onUpdate) {
      onUpdate(properties);
    }
  };

  return (
    <MultiSelect
      prompt={prompt}
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
    />
  );
};

export default MultiSelectFormRenderer;
