import { useState } from "react";
import { EmployeeAvailability } from "shared/types/EmployeeAvailability";
import Button from "../ui/Button";
import EmployeeAvailabilityModalDialog from "./EmployeeAvailabilityModalDialog";

function AddEmployeeAvailability(props: {
  onSuccess: (employeeAvailability: EmployeeAvailability) => void;
}) {
  const { onSuccess } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div>
      <Button
        secondary
        type="button"
        onClick={() => setIsModalOpen(true)}
        className="my-4 text-lg"
      >
        Add Availability
      </Button>
      <EmployeeAvailabilityModalDialog
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        employeeAvailability={null}
        onSuccess={(employeeAvailability: EmployeeAvailability) => {
          onSuccess(employeeAvailability);
          setIsModalOpen(false);
        }}
      />
    </div>
  );
}

export default AddEmployeeAvailability;
