import { Navigate } from "react-router-dom";
import Store from "../../store";

const PublicRoute = ({ children }: { children: any }) => {
  const { user } = Store();
  if (!user) {
    return children;
  } else {
    return <Navigate to="/locations" />;
  }
};

export default PublicRoute;
