import { ReactNode } from "react";
import UseSwipe from "../../hooks/UseSwipe";
import Store from "../../store";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

function tap(
  sidebarVisible: boolean,
  sidebarCollapsed: boolean,
  collapseSidebar: () => void,
) {
  if (sidebarVisible && !sidebarCollapsed && window.innerWidth < 640) {
    collapseSidebar();
  }
}

function swipeRight(
  sidebarVisible: boolean,
  sidebarCollapsed: boolean,
  uncollapseSidebar: () => void,
) {
  if (sidebarVisible && sidebarCollapsed) {
    uncollapseSidebar();
  }
}

function swipeLeft(
  sidebarVisible: boolean,
  sidebarCollapsed: boolean,
  collapseSidebar: () => void,
) {
  if (sidebarVisible && !sidebarCollapsed) {
    collapseSidebar();
  }
}

const NavigationContainer = ({
  children,
  sidebar,
}: {
  children: ReactNode;
  sidebar?: ReactNode;
}) => {
  const fragment = children;

  const {
    sidebarVisible,
    sidebarCollapsed,
    collapseSidebar,
    uncollapseSidebar,
  } = Store();

  const swipeHandlers = UseSwipe({
    onSwipedLeft: () =>
      swipeLeft(sidebarVisible, sidebarCollapsed, collapseSidebar),
    onSwipedRight: () =>
      swipeRight(sidebarVisible, sidebarCollapsed, uncollapseSidebar),
  });

  return (
    <div className="relative flex flex-col w-full overflow-hidden h-dvh max-h-dvh">
      <NavigationBar />
      <div className="flex flex-row flex-grow overflow-hidden">
        {sidebar && <div>{sidebar}</div>}
        <div
          className="flex flex-col flex-auto overflow-x-hidden"
          onClick={() => tap(sidebarVisible, sidebarCollapsed, collapseSidebar)}
          {...swipeHandlers}
        >
          <div
            className={`p-3 max-w-full break-anywhere overflow-auto lg:min-w-[1024px] ${sidebarVisible ? "min-w-[360px]" : ""}`.trim()}
          >
            {fragment}
          </div>
          <div className="flex-grow"></div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default NavigationContainer;
