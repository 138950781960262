import Money from "@rschpdr/react-money-input";
import { SetStateAction } from "react";
import Label from "./Label";

function MoneyInput(props: {
  elementId: string;
  labelText: string;
  amount: number;
  setAmount: (value: SetStateAction<number>) => void;
  className?: string;
  currency?: string;
  required?: boolean;
  autoFocus?: boolean;
}) {
  const { elementId, labelText, amount, setAmount, className } = props;
  const currency = props.currency === undefined ? "usd" : props.currency;
  const required = props.required === undefined ? false : props.required;
  const autoFocus = props.autoFocus === undefined ? false : props.autoFocus;

  function handleChange(e: { target: { value: SetStateAction<number> } }) {
    setAmount((e.target.value as number) * 100);
  }

  return (
    <div className="w-full mb-5">
      <Label htmlFor={elementId} className="text-left">
        {required ? labelText + "*" : labelText}
      </Label>
      <Money
        value={amount / 100}
        onChange={handleChange}
        currencyConfig={{
          locale: "en-US",
          currencyCode: currency,
          currencyDisplay: "symbol",
          useGrouping: true,
          minimumFractionDigits: undefined,
        }}
        required={required}
        autoFocus={autoFocus}
        className={`block w-full h-10 p-2 text-base border-2 rounded-lg border-monkey-purple border:focus-monkey-purple focus:outline-none focus:ring-2 focus:ring-monkey-purple focus:border-monkey-purple ${className ?? ""}`.trim()}
      />
    </div>
  );
}

export default MoneyInput;
