import { getDocWithDates, getDocsWithDates } from "@/utils/FirestoreHelpers";
import {
  collection,
  doc,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { Employee } from "shared/types/Employee";
import { Location } from "shared/types/Location";
import { Product } from "shared/types/Product";
import { ChargeCustomers } from "shared/utils/Enums";
import { v4 as uuidv4 } from "uuid";
import { app, auth } from "../firebase";
import {
  deleteFile,
  fileExists,
  uploadFileFromDataURL,
} from "./StorageService";
import { getUserById } from "./UserService";

const db = getFirestore(app);

/**
 * Fetch location by ID
 * @param {string} locationId - The ID of the location to fetch
 * @return {Promise<Location>} - The location data
 * @throws {Error} - If the location is not found or invalid
 */
export const getLocationById = async (
  locationId: string,
): Promise<Location> => {
  const locationRef = doc(db, "locations", locationId);
  const locationData = await getDocWithDates(locationRef);

  if (locationData.deleted) {
    throw new Error("Location not found");
  }

  // Fetch employee data for each employee in the location
  const employeePromises: Promise<Employee>[] = locationData.employees.map(
    async (employeeId: string) => {
      const employeeRef = doc(db, "employees", employeeId);
      const employeeData = await getDocWithDates(employeeRef);
      if (!employeeData.deleted) {
        return employeeData as Employee;
      }
      return null;
    },
  );

  locationData.employees = (await Promise.all(employeePromises)).filter(
    (employee) => employee !== null,
  );

  // Fetch product data for each product in the location
  const productPromises: Promise<Product>[] = locationData.products.map(
    async (productId: string) => {
      const productRef = doc(db, "products", productId);
      const productData = await getDocWithDates(productRef);
      if (!productData.deleted) {
        return productData as Product;
      }
      return null;
    },
  );

  locationData.products = (await Promise.all(productPromises)).filter(
    (product) => product !== null,
  );

  return locationData as Location;
};

/**
 * Fetch locations by user email
 * @param {string | null} email - The email of the user
 * @return {Promise<Location[]>} - The list of locations associated with the user
 */
export const getLocationsByUserEmail = async (
  email: string | null,
): Promise<Location[]> => {
  if (!email) {
    console.error("User email is undefined");
    return [];
  }
  const locationsRef = collection(db, "locations");
  const adminsQuery = query(
    locationsRef,
    where("admins", "array-contains", email),
  );
  const usersQuery = query(
    locationsRef,
    where("users", "array-contains", email),
  );
  const [adminsData, usersData] = await Promise.all([
    getDocsWithDates(adminsQuery),
    getDocsWithDates(usersQuery),
  ]);
  const locationsMap = new Map<string, Location>();
  adminsData.forEach((data) => {
    if (!data.deleted) {
      locationsMap.set(data.id, data as Location);
    }
  });
  usersData.forEach((data) => {
    if (!data.deleted) {
      locationsMap.set(data.id, data as Location);
    }
  });

  const locationPromises = Array.from(locationsMap.keys()).map((locationId) =>
    getLocationById(locationId),
  );

  return await Promise.all(locationPromises);
};

/**
 * Create a new location
 * @param {Location} location - The location data to create
 * @return {Promise<void>}
 */
export const createLocation = async (location: Location): Promise<void> => {
  const user = await getUserById(auth?.currentUser?.uid);
  location.products ??= [];
  location.stripeAccountId = user?.stripeAccountId ?? "";
  location.chargeCustomers = ChargeCustomers.Automatically;

  const employees = location.employees ?? [];
  const locationEmployees = [];
  for (const employee of employees) {
    if (!employee.id) {
      employee.id = uuidv4();
    }
    const employeeRef = doc(db, "employees", employee.id);
    if (!employee.created) {
      employee.created = new Date();
    }
    await setDoc(employeeRef, { employee, deleted: false });
    locationEmployees.push(employee.id);
  }

  const products = location.products ?? [];
  const locationProducts = [];
  for (const product of products) {
    if (!product.id) {
      product.id = uuidv4();
    }
    const productRef = doc(db, "products", product.id);
    if (!product.created) {
      product.created = new Date();
    }
    await setDoc(productRef, { product: product, deleted: false });
    locationProducts.push(product.id);
  }

  const existingEmployees = await getDocsWithDates(collection(db, "employees"));
  const existingProducts = await getDocsWithDates(collection(db, "products"));

  const employeesToDelete = existingEmployees.filter(
    (emp) => !location.employees.some((e) => e.id === emp.id),
  );
  const productsToDelete = existingProducts.filter(
    (srv) => !location.products.some((s) => s.id === srv.id),
  );

  for (const emp of employeesToDelete) {
    await setDoc(
      doc(db, "employees", emp.id),
      { deleted: true },
      { merge: true },
    );
  }
  for (const srv of productsToDelete) {
    await setDoc(
      doc(db, "products", srv.id),
      { deleted: true },
      { merge: true },
    );
  }

  await setDoc(doc(db, "locations", location.id), {
    ...location,
    employees: locationEmployees,
    products: locationProducts,
    created: new Date(),
    deleted: false,
  });
  await updateLocation(location);
};

/**
 * Update an existing location
 * @param {Location} location - The location data to update
 * @return {Promise<void>}
 */
export const updateLocation = async (location: Location): Promise<void> => {
  if (!location.id) {
    location.id = uuidv4();
  }
  const imagePath = `/locations/${location.id}/${location.id}`;
  if (location.image.startsWith("data")) {
    if (await fileExists(imagePath)) {
      await deleteFile(imagePath);
    }
    await uploadFileFromDataURL(imagePath, location.image);
    location.image = imagePath;
  }

  const locationEmployees = [];
  for (const employee of location.employees) {
    if (!employee.id) {
      employee.id = uuidv4();
    }
    const employeeRef = doc(db, "employees", employee.id);
    if (!employee.created) {
      employee.created = new Date();
    }
    await setDoc(employeeRef, { employee, deleted: false });
    locationEmployees.push(employee.id);

    const employeeImagePath = `/locations/${location.id}/${employee.id}`;
    if (employee.image.startsWith("data")) {
      if (await fileExists(employeeImagePath)) {
        await deleteFile(employeeImagePath);
      }
      await uploadFileFromDataURL(employeeImagePath, employee.image);
      employee.image = employeeImagePath;
    }
    employee.phone ??= "";
  }

  const locationProducts = [];
  for (const product of location.products) {
    if (!product.id) {
      product.id = uuidv4();
    }
    const productRef = doc(db, "products", product.id);
    if (!product.created) {
      product.created = new Date();
    }
    await setDoc(productRef, { ...product, deleted: false });
    locationProducts.push(product.id);
  }

  const existingEmployees = await getDocsWithDates(collection(db, "employees"));
  const existingProducts = await getDocsWithDates(collection(db, "products"));

  const employeesToDelete = existingEmployees.filter(
    (emp) => !location.employees.some((e) => e.id === emp.id),
  );
  const productsToDelete = existingProducts.filter(
    (srv) => !location.products.some((s) => s.id === srv.id),
  );

  for (const emp of employeesToDelete) {
    await setDoc(
      doc(db, "employees", emp.id),
      { deleted: true },
      { merge: true },
    );
  }
  for (const srv of productsToDelete) {
    await setDoc(
      doc(db, "products", srv.id),
      { deleted: true },
      { merge: true },
    );
  }

  await setDoc(doc(db, "locations", location.id), {
    ...location,
    employees: locationEmployees,
    products: locationProducts,
  });
};

/**
 * Soft delete a location by ID
 * @param {string} locationId - The ID of the location to delete
 * @return {Promise<void>}
 */
export const deleteLocationById = async (locationId: string): Promise<void> => {
  const locationRef = doc(db, "locations", locationId);
  await setDoc(locationRef, { deleted: true }, { merge: true });
};
