import {
  Auth,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../ui/Button";
import TextField from "../ui/TextField";

const handleSubmit = (
  e: React.FormEvent,
  auth: Auth,
  actionCode: string,
  newPassword: string,
  setMessage: (message: string) => void,
  setSuccess: (show: boolean) => void,
) => {
  e.preventDefault();
  confirmPasswordReset(auth, actionCode, newPassword)
    .then(() => {
      setSuccess(true);
      setMessage("Your password has been reset successfully.");
    })
    .catch((error) => {
      console.error(error);
      setMessage("Failed to reset password. Please try again.");
    });
};

const ResetPassword = ({
  auth,
  actionCode,
}: {
  auth: Auth;
  actionCode: string;
  continueUrl: string;
  lang: string;
}) => {
  const [isPasswordResetCodeVerified, setIsPasswordResetCodeVerified] =
    useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setUserEmail(email);
      })
      .catch((error) => {
        console.error(error);
        setIsPasswordResetCodeVerified(false);
      });
  }, [auth, actionCode]);

  return (
    <div className="w-full max-w-screen-sm px-5 mx-auto mt-8">
      {isPasswordResetCodeVerified ? (
        <div>
          <h1 className="text-3xl font-bold text-center text-monkey-purple">
            Reset Password
          </h1>
          {!success && (
            <>
              <div className="mt-8 mb-3">{userEmail}</div>
              <form
                onSubmit={(e) =>
                  handleSubmit(
                    e,
                    auth,
                    actionCode,
                    newPassword,
                    setMessage,
                    setSuccess,
                  )
                }
                className="text-center"
              >
                <TextField
                  type="password"
                  elementId="new-password"
                  labelText="New password"
                  value={newPassword}
                  onInput={setNewPassword}
                  required={true}
                  autoFocus={true}
                  submitOnEnter={true}
                />
                <Button primary type="submit">
                  Reset Password
                </Button>
              </form>
            </>
          )}
          {message && <p className="mt-5 text-center">{message}</p>}
          {success && (
            <div className="flex justify-center mt-5">
              <Button primary type="button" onClick={() => navigate("/")}>
                Return to login page
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div>
          Uh oh! Your password reset link has expired. Please try again.
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
