import { ReactNode } from "react";

const SplitScreen = ({ children }: { children: ReactNode[] }) => {
  const [left, right] = children;
  return (
    <div className="flex overflow-hidden max-h-dvh">
      <div className="hidden overflow-auto basis-6/12 lg:block">{left}</div>
      <div className="overflow-auto grow lg:basis-6/12">{right}</div>
    </div>
  );
};

export default SplitScreen;
