import AddressLookup from "@/components/ui/AddressLookup";
import TextField from "@/components/ui/TextField";
import TimezoneSelect from "@/components/ui/TimezoneSelect";
import { logError } from "@/services/LoggingService";
import { getFileURL } from "@/services/StorageService";
import Store from "@/store";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import { Location } from "shared/types/Location";
import NavigationContainer from "../../components/layout/NavigationContainer";
import LocationSidebar from "../../components/locations/LocationSidebar";
import Button from "../../components/ui/Button";
import {
  deleteLocationById,
  getLocationById,
  updateLocation,
} from "../../services/LocationService";
import UploadPlaceholderImage from "/images/upload-image-placeholder.jpg";

const Overview = () => {
  const { setIsLoading } = Store();
  const navigate = useNavigate();
  const { locationId } = useParams();
  const addressLookup = useRef<HTMLInputElement>(null);
  const [location, setLocation] = useState<Location>();
  const [image, setImage] = useState("");
  const avatarEditor = useRef<AvatarEditor>(null);
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [address, setAddress] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const timezoneData = useQuery({
    queryKey: ["timezone", latitude, longitude],
    queryFn: async () => {
      if (latitude && longitude) {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${new Date().getTime() / 1000}&key=${import.meta.env.VITE_GOOGLE_TIMEZONE_API_KEY}`,
        );
        return await response.json();
      } else {
        return null;
      }
    },
  });

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId!);
      setLocation(location);
      setName(location.name);
      setSlug(location.id);
      setAddress(location.address);
      setTimeZone(location.timezone);
      if (location.image) {
        setImage((await getFileURL(location.image)) ?? "");
      }
    };
    fetchData()
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  }, [locationId]);

  useEffect(() => {
    if (timezoneData && timezoneData.data && timezoneData.isSuccess) {
      setTimeZone(timezoneData.data.timeZoneId);
    }
  }, [timezoneData]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    await updateLocation({ ...location!, name, address, timezone: timeZone });
    setIsLoading(false);
  };

  const onDrop = (acceptedFiles: File[]) => {
    setImage(URL.createObjectURL(acceptedFiles[0]));
    setIsEditingImage(true);
  };

  const onImageClick = () => {
    setIsEditingImage(true);
  };

  const onSaveImage = async () => {
    if (avatarEditor.current && image) {
      setIsLoading(true);
      const imagePath = `/locations/${locationId}/${locationId}`;
      const canvas = avatarEditor.current.getImageScaledToCanvas();
      const dataUrl = canvas.toDataURL();
      await updateLocation({ ...location!, image: dataUrl });
      setImage((await getFileURL(imagePath)) ?? "");
      setIsLoading(false);
    }
    setIsEditingImage(false);
  };

  const deleteLocation = async () => {
    setIsLoading(true);
    await deleteLocationById(locationId!)
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
    navigate("/locations");
  };

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId!} />}>
      <div className="mb-6 text-4xl text-center text-monkey-purple">{name}</div>
      <form onSubmit={onSubmit}>
        <div>
          <Dropzone onDrop={onDrop} noKeyboard noClick={isEditingImage}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="flex flex-col items-center">
                {isEditingImage ? (
                  <>
                    <AvatarEditor
                      width={250}
                      height={250}
                      image={image || UploadPlaceholderImage}
                      ref={avatarEditor}
                      border={25}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={1}
                      rotate={0}
                      borderRadius={0}
                    />
                    <div className="flex justify-center mt-2 space-x-4">
                      <Button
                        secondary
                        type="button"
                        className="w-32"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEditingImage(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        primary
                        type="button"
                        className="w-32"
                        onClick={(e) => {
                          e.stopPropagation();
                          onSaveImage();
                        }}
                      >
                        Save Image
                      </Button>
                    </div>
                  </>
                ) : (
                  <img
                    src={image || UploadPlaceholderImage}
                    alt="Location"
                    className="cursor-pointer w-[250px] h-[250px]"
                    onClick={onImageClick}
                  />
                )}
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </div>
        <div className="mb-2">
          <h2 className="mt-4 mb-2 text-2xl text-monkey-purple">
            Booking Link
          </h2>
          <a
            href={`/b/${slug}`}
          >{`${import.meta.env.VITE_APP_MONKEY_URL}/b/${slug}`}</a>
        </div>
        <div className="flex space-x-4">
          <Button secondary type="button">
            Copy
          </Button>
          <Button secondary type="button">
            Edit
          </Button>
        </div>
        <div className="space-y-4">
          <h2 className="mt-4 text-2xl text-monkey-purple">Location Details</h2>
          <div className="flex flex-wrap justify-center">
            <div className="basis-6/12 min-w-[250px]">
              <TextField
                elementId="location-name"
                labelText="Name"
                required={true}
                value={name}
                onInput={setName}
              />
              <AddressLookup
                elementId="location-address"
                labelText="Address"
                required={true}
                ref={addressLookup}
                value={address}
                onInput={setAddress}
                onPlaceSelected={(place: google.maps.places.PlaceResult) => {
                  if (place !== undefined) {
                    setAddress(place.formatted_address ?? "");
                    setLatitude(place.geometry?.location?.lat() ?? 0);
                    setLongitude(place.geometry?.location?.lng() ?? 0);
                  }
                }}
              />
              <TimezoneSelect
                timezone={timeZone}
                setTimezone={setTimeZone}
                required={true}
              />
            </div>
            <div className="basis-6/12 min-w-[250px]"></div>
          </div>
          <div className="flex justify-center space-x-4">
            <Button secondary type="button" onClick={deleteLocation}>
              Delete
            </Button>
            <Button primary type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </NavigationContainer>
  );
};

export default Overview;
