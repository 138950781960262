import { Employee } from "shared/types/Employee";
import PlaceholderImage from "/images/avatar-placeholder.png";

const EmployeeFilter = (props: {
  employees: Employee[];
  selectedEmployee: Employee;
  setSelectedEmployee: (selectedEmployees: Employee) => void;
}) => {
  const { employees, selectedEmployee, setSelectedEmployee } = props;

  const selectEmployee = (employee: Employee) => {
    if (selectedEmployee.id !== employee.id) {
      setSelectedEmployee(employee);
    }
  };

  return (
    <div className="flex space-x-4 overflow-auto">
      {employees.map((employee, index) => {
        return (
          <div key={index} className={`my-4 text-xl rounded-full`}>
            <button
              className={`rounded-full w-[75px] h-[75px] ${selectedEmployee.id === employee.id ? "border-monkey-purple border-4" : "border-none"}`}
              onClick={() => selectEmployee(employee)}
            >
              <div>
                <img
                  src={employee.image || PlaceholderImage}
                  className="rounded-full"
                ></img>
                <div
                  className={`text-sm  ${selectedEmployee.id === employee.id ? "mt-2" : "mt-1"}`}
                >
                  {employee.firstName}
                </div>
              </div>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default EmployeeFilter;
